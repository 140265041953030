export const calculateCoefficient = (oddData) => {
  if (oddData?.res) {
    return oddData.res
  } else {
    if (oddData.price < 0) {
      return parseFloat(-100 / oddData.price)?.toFixed(2)
    } else {
      return parseFloat(oddData.price / 100)?.toFixed(2)
    }
  }
}
