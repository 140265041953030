import { getProfileSetting } from '@/firebase/utils'
import { formatDate } from '@/utils/dates'
import { successMsg } from '@/utils/toast'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StatusChip } from './StatusChip'
import { toPlainString } from '@/utils/toPlainString'
import { dotColor } from '../../config'
import authSelector from '@/redux/selectors/auth'
import { useIntl } from 'react-intl'
import { LinkIcon, DuplicateIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { Profile, LinkChain, DoubleCheck, LilbitLogo } from '@/icons/index'

const SingleSlip = memo(
  ({ betData, topRounded, bottomRounded, slipBorder, intl }) => {
    return (
      <div
        className={`bg-white/5 overflow-hidden ${
          topRounded ? 'rounded-t-xl' : ''
        } ${bottomRounded ? 'rounded-b-xl' : ''} ${
          slipBorder ? 'border-t-2 border-white/30 border-dotted' : ''
        }`}
      >
        <div className="bg-white/5 py-2 px-6 flex flex-row items-center justify-start gap-2 relative">
          <h4 className="overflow-ellipsis flex-1 text-left font-inter font-medium text-xs">
            Transaction Information
          </h4>
        </div>
        <div className="px-6 py-4">
          <div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                Bet Tx
              </span>
              <div className="flex space-x-3">
                <div className="max-w-[150px] sm:max-w-[230px] scroller">
                  <span className="text-xs">{betData.getTx}</span>
                </div>
                <DuplicateIcon
                  className="shrink-0 w-5 h-5 cursor-pointer"
                  onClick={() => {
                    window.navigator.clipboard.writeText(betData.getTx)
                    successMsg(
                      intl.formatMessage(
                        {
                          id: 'success.betTxCopied"',
                          defaultMessage: `BetTx ${betData.getTx} Successfully Copied`,
                        },
                        { tx: betData.getTx },
                      ),
                    )
                  }}
                />
                <a
                  className="shrink-0"
                  href={`https://whatsonchain.com/tx/${betData.getTx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkIcon className="w-5 h-5 rotate-45 text-yellow-500" />
                </a>
              </div>
            </div>
            <div className="flex flex-col items-start mt-2">
              <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                Payout Tx
              </span>
              <div className="flex space-x-3">
                <div className="max-w-[150px] sm:max-w-[230px] scroller">
                  <span className="text-xs">{betData.sendTx}</span>
                </div>
                <DuplicateIcon
                  className="shrink-0 w-5 h-5 cursor-pointer"
                  onClick={() => {
                    window.navigator.clipboard.writeText(betData.sendTx)
                    successMsg(
                      intl.formatMessage(
                        {
                          id: 'success.payoutTxCopied',
                          defaultMessage: `PayoutTx ${betData.sendTx} Successfully Copied`,
                        },
                        { tx: betData.sendTx },
                      ),
                    )
                  }}
                />
                <div className="shrink-0">
                  <a
                    href={`https://whatsonchain.com/tx/${betData.sendTx}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon className="w-5 h-5 rotate-45 text-yellow-500" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between space-x-1 text-sm font-medium font-inter text-white relative">
              {betData.earn > 0 ? (
                <DoubleCheck className="w-6 h-6 text-green-500 stroke-2" />
              ) : betData.earn === 0 ? (
                <StatusChip
                  status={'Push'}
                  className="bg-[#F7941D]/20 text-[#F7941D]"
                />
              ) : (
                <XIcon className="w-6 h-6 text-red-500 stroke-2" />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
SingleSlip.displayName = 'SingleSlip'

export default function BetSlipsModel({
  isOpen,
  onClose,
  data,
  bsvToUsd,
  usdToBsv,
}) {
  const { user } = useSelector(authSelector)

  const uid = data?.uid
  const [betPlacedUser, setBetPlacedUser] = useState({})
  const intl = useIntl()
  useEffect(() => {
    getProfile(uid)
  }, [uid])

  async function getProfile(uid) {
    const res = await getProfileSetting(uid)

    if (res) {
      setBetPlacedUser(res)
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20 bg-white" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 backdrop-blur-md bg-black/90`} />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-row items-center justify-center">
                <Dialog.Panel
                  className={
                    'transform transition-all shadow-2xl w-full relative'
                  }
                >
                  <div
                    className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
                    onClick={onClose}
                  >
                    <XIcon className="w-5 h-5 text-gray-300" />
                  </div>

                  <div className="rounded-b-xl bg-white/5 pt-6 px-6 overflow-auto scroller max-h-[calc(100vh-200px)] min-w-[23rem] md:min-w-[28rem]">
                    {/* Info */}
                    <div className="space-y-3 flex flex-col items-center">
                      <div className="flex flex-row items-center justify-center text-base font-semibold font-inter">
                        <h4>{'Bet' + ' ID' + ' ' + data?.betID}</h4>
                        <div className="flex flex-row items-center gap-2 ml-2 relative w-14 justify-around">
                          <div>
                            <DuplicateIcon
                              className="shrink-0 w-6 h-6 cursor-pointer"
                              onClick={() => {
                                window.navigator.clipboard.writeText(
                                  data?.betID,
                                )
                                successMsg(
                                  intl.formatMessage(
                                    {
                                      id: 'success.betIdCopied',
                                      defaultMessage: `BetID ${data?.betID} Successfully Copied`,
                                    },
                                    { betID: data?.betID },
                                  ),
                                )
                              }}
                            />
                          </div>
                          <div>
                            <LinkChain
                              className="shrink-0 w-6 h-6 cursor-pointer"
                              onClick={() => {
                                window.navigator.clipboard.writeText(
                                  `${window.location.host}/embed?betId=${data.betID}&game=plinko`,
                                )
                                successMsg(
                                  intl.formatMessage({
                                    id: 'success.betSlipLinkCopied',
                                    defaultMessage:
                                      'Bet slip link successfully copied',
                                  }),
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-[#98A2B3] font-inter space-x-2 flex flex-row items-center justify-center">
                        <h4 className="font-normal text-base">Placed by</h4>
                        <div className="bg-white/5 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
                          <Profile className="w-5 h-5 text-gray-300" />
                          {user ? (
                            <h4 className="font-medium text-sm capitalize">
                              {betPlacedUser.uid
                                ? betPlacedUser?.username ||
                                  betPlacedUser?.displayName
                                : ''}
                            </h4>
                          ) : (
                            <h4 className="font-medium text-sm capitalize">
                              {'Hidden'}
                            </h4>
                          )}
                        </div>
                      </div>
                      <div className="font-inter font-normal text-xs">
                        {'on' +
                          ' ' +
                          formatDate(data?.date, 'MM/DD/YYYY') +
                          ' ' +
                          formatDate(data?.date, 'hh:mm A')}
                      </div>
                    </div>

                    <div className="mt-6 ml-[2rem] w-[16rem] sm:w-[21rem] mb-2">
                      <div>
                        <SingleSlip
                          betData={data}
                          topRounded
                          bottomRounded
                          slipBorder={false}
                          isMulti={false}
                          intl={intl}
                        />
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flex items-center justify-center relative">
                        <LilbitLogo className="w-[6.5rem] h-8" />
                      </div>
                      <div className="mt-3 mb-10 space-y-4 font-inter text-xs">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Floor</h3>
                          <div
                            className={`w-6 h-6 ${
                              dotColor[data.floorColor]
                            } rounded-md border-2 border-[#D2A040]`}
                          />
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Odds</h3>
                          <h4 className="font-medium">{data.res}</h4>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Total Bet</h3>

                          <h4 className="font-medium">
                            ${Number(bsvToUsd(data.amount).toFixed(5) || 0.0)}
                          </h4>
                        </div>

                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Payout</h3>

                          <h4 className="font-medium">
                            $
                            {toPlainString(
                              Number(bsvToUsd(data.amount * data.res)).toFixed(
                                5,
                              ) || 0.0,
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
        +{' '}
      </Dialog>
    </Transition>
  )
}
