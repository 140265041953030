import { BsvIcon } from '@/icons/index'
import { calculateCoefficient } from '@/utils/plinkoCoefficient'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'

ModuleRegistry.registerModules([ClientSideRowModelModule])

const statusColors = {
  active: 'bg-[#F7941D]/20 text-[#F7941D]',
  push: 'bg-[#F7941D]/20 text-[#F7941D]',
  win: 'bg-[#12B76A]/10 text-[#12B76A]',
  lose: 'bg-[#F04438]/10 text-[#F04438]',
  grey: 'bg-white/10 text-white',
  yellow: 'bg-[#F79009]/20 text-[#F79009]',
  red: 'bg-[#F04438]/10 text-[#F04438]',
  blue: 'bg-[#0096FF]/10 text-[#0096FF]',
  green: 'bg-[#12B76A]/10 text-[#12B76A]',
}

const HistoryTable = ({
  allBets,
  bsvToUsd,
  setReplayGame,
  replayGame,
  isLoading,
  onIdPress,
  isHome = false,
  isReplay = false,
}) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const ReplayRenderer = ({ data }) => {
    const { bigBall, smallBall, category, floorColor, speed, date } = data
    const isActiveReplay = replayGame?.date === date

    return (
      <button
        onClick={() =>
          setReplayGame({
            bigBall,
            smallBall,
            category,
            floorColor,
            speed,
            date,
          })
        }
        disabled={isLoading || isActiveReplay}
        className="text-sm font-medium rounded-full border border-white disabled:opacity-20 p-1 flex justify-center items-center hover:opacity-50"
      >
        {isActiveReplay ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#eab300"
            className="w-5 h-5 animate-spin"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#eab300"
            className="w-5 h-5 pl-[2px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
            />
          </svg>
        )}
      </button>
    )
  }

  const PlinkoDate = (props) => {
    const data = props.data

    return (
      <div className="text-sm font-medium capitalize flex gap-x-5 w-[100%]">
        <div className="p-2">
          <span>{data?.date}</span>
        </div>
      </div>
    )
  }

  const BetIdRenderer = (props) => {
    const data = props.data

    return (
      <div className="text-sm font-medium  cursor-pointer" title={data?.betID}>
        {data?.betID}
      </div>
    )
  }

  const PlinkoResultRenderer = ({ data }) =>
    data.floorColor ? (
      <div
        className={`${
          statusColors[data.floorColor]
        } rounded-2xl px-2 py-0.5 text-sm font-medium`}
      >
        {data.floorColor}
      </div>
    ) : null

  const BetRenderer = ({ data }) => {
    const bet = data.amount

    return (
      <div className="text-sm font-medium space-y-2 cursor-pointer">
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" />
          {bet}
        </div>
        <div>$ {Number(bsvToUsd(bet) || 0.0).toFixed(5)}</div>
      </div>
    )
  }

  const PayoutRenderer = ({ data }) => {
    const earn = data.res * data.amount

    return (
      <div
        className={`text-sm font-medium space-y-2 ${
          earn > 0 ? 'text-yellow-600' : 'text-gray-600'
        }`}
      >
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" />
          {earn.toFixed(5)}
        </div>
        <div>
          {earn > 0 ? '+' : earn === 0 ? '' : '-'}${' '}
          {Number(bsvToUsd(earn) || 0.0).toFixed(5)}
        </div>
      </div>
    )
  }

  const CoefRenderer = ({ data }) => (
    <div
      className={`${
        statusColors[data.floorColor]
      } rounded-2xl px-2 py-0.5 text-sm font-medium`}
    >
      {calculateCoefficient(data)}
    </div>
  )

  const getRowId = useMemo(() => {
    return (params) => params.data.getTx
  }, [])

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }

  const casinoColumnDefs = [
    {
      headerName: 'Bet ID',
      field: 'betID',
      cellRenderer: BetIdRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Bet',
      field: 'betPrice',
      cellRenderer: BetRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Payouts',
      field: 'payouts',
      cellRenderer: PayoutRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Time(UTC)',
      field: 'date',
      cellRenderer: PlinkoDate,
      minWidth: 160,
      onCellClicked,
    },
    {
      headerName: 'Multiplier',
      field: 'multiplier',
      cellRenderer: CoefRenderer,
      minWidth: 130,
      maxWidth: 130,
      onCellClicked,
    },
    {
      headerName: 'My Pick',
      field: 'myPick',
      cellRenderer: PlinkoResultRenderer,
      minWidth: 150,
      onCellClicked,
    },
  ]

  if (isReplay) {
    casinoColumnDefs.push({
      headerName: 'Replay',
      field: 'replay',
      cellRenderer: ReplayRenderer,
      minWidth: 150,
    })
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: false,
    }
  }, [])

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto ${
        isHome ? 'w-full' : 'w-[95%] 2xl:w-[97%]'
      } ${isReplay ? 'my-2' : 'my-8'} bg-black/70 rounded-md`}
      id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          rowData={allBets}
          columnDefs={casinoColumnDefs}
          defaultColDef={defaultColDef}
          animateRows
          getRowId={getRowId}
          rowHeight={70}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; ">No Results Found</span>'
          }
          overlayLoadingTemplate='<span class="plinko-loader"></span>'
          onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
        />
      </div>
    </div>
  )
}

export default HistoryTable
