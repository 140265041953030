const initialState = {
  isLoading: false,
  refresh: false,
  sound: true,
  floor: 'blue',
  bgSound: true,
  ballLoc: 179,
  ballSpeed: 1,
  lastMultipliers: [],
}

const dotColor = {
  blue: 'bg-[#333695]',
  green: 'bg-[#3D7D19]',
  yellow: 'bg-[#BEA223]',
  red: 'bg-[#983012]',
}

const floorButtons = {
  vintage: [
    { color: 'blue', bg: 'bg-btn-blue' },
    { color: 'green', bg: 'bg-btn-green' },
    { color: 'yellow', bg: 'bg-btn-yellow' },
    { color: 'red', bg: 'bg-btn-red' },
  ],
  jungle: [
    { color: 'blue', bg: 'bg-btn-jng-blue' },
    { color: 'green', bg: 'bg-btn-jng-green' },
    { color: 'yellow', bg: 'bg-btn-jng-yellow' },
    { color: 'red', bg: 'bg-btn-jng-red' },
  ],
  bonus: [],
}

const buttonHeights = {
  jungle: 'h-6 sm:h-10',
  vintage: 'h-9 sm:h-16',
}

const floorPriceDollarRate = {
  blue: { min: 0.01, max: 500 },
  green: { min: 0.01, max: 90 },
  yellow: { min: 0.01, max: 22.5 },
  red: { min: 0.01, max: 5 },
  bonus: { min: 0.01, max: 1 },
}

const multiplerCategory = {
  blue: 0x400000,
  green: 0x800000,
  yellow: 0x1000000,
  red: 0x2000000,
  bonus: 0x400000,
}

const pins = {
  miniPins: {
    startPins: 3,
    pinSize: 2.5,
    pinGap: 17,
  },
  bigPins: {
    startPins: 3,
    pinSize: 3.7,
    pinGap: 24,
  },
}

const ball = {
  miniBall: {
    ballSize: 4.3,
  },
  bigBall: {
    ballSize: 6,
  },
}

const gameConfigs = (isBigScreen) => {
  if (isBigScreen) {
    return {
      ...pins.bigPins,
      ...ball.bigBall,
      ...world.bigWorld,
    }
  } else {
    return {
      ...pins.miniPins,
      ...ball.miniBall,
      ...world.miniWorld,
    }
  }
}

const engine = {
  engineGravity: (type) => {
    switch (type) {
      case 'slow':
        return 0.5
      case 'fast':
        return 2
      default:
        return 1
    }
  },
}

const world = {
  miniWorld: {
    width: 320,
    height: 422,
  },
  bigWorld: {
    width: 455,
    height: 600,
  },
}

const colors = {
  background: 'transparent',
  primary: '#213743',
  secondary: '#3d5564',
  text: '#F2F7FF',
  purple: '#C52BFF',
  purpleDark: '#8D27B3',
}

export const config = {
  gameConfigs,
  engine,
  colors,
}

export {
  initialState,
  floorButtons,
  buttonHeights,
  floorPriceDollarRate,
  dotColor,
  multiplerCategory,
}
